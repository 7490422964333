import React, { useEffect, useContext, cloneElement } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router';
import { Grid, Button, Typography, CircularProgress } from '@mui/material';
import CateringContext from '../CateringContext';

function CateringContent({ children }) {
    const {
        displayTitle,
        setDisplayTitle,
        newEventPage,
        setNewEventPage,
        updateForm,
        setEventOrder,
        setConversation,
        setLoading,
        clearAll,
        retrieveEventsByInquiryId,
        processConversationData,
        loadingSummaryData,
        setLoadingSummaryData,
    } = useContext(CateringContext);

    // const { restauHook } = useContext(RestauInfoContext);

    const navigate = useNavigate();
    const location = useLocation();
    const { RestauId, CateringInquiryId } = useParams();

    const updateTitleAndEventData = async () => {
        if (
            location.pathname.endsWith('/dashboard') ||
            location.pathname.endsWith('/catering')
        ) {
            clearAll();
            setNewEventPage(false);
            setDisplayTitle('Dashboard');
        } else if (location.pathname.endsWith('/search')) {
            clearAll();
            setNewEventPage(false);
            setDisplayTitle('Search Catering Events');
        } else if (location.pathname.endsWith('/newevent')) {
            clearAll();
            setNewEventPage(true);
            setDisplayTitle('New Event');
        } else if (location.pathname.endsWith('/setting')) {
            clearAll();
            setNewEventPage(false);
            setDisplayTitle('Catering Inquiry Form Settings');
        } else if (location.pathname.endsWith('/search')) {
            clearAll();
            setNewEventPage(false);
            setDisplayTitle('Catering Inquiry Search');
        } else {
            // fetch event name with CateringInquiryId and setDisplayTitle
            //await fetchAndLoadEventData(RestauId, CateringInquiryId);
        }
    };

    const fetchAndLoadEventData = async (RestauId, CateringInquiryId) => {
        setLoading(true);
        setNewEventPage(false);
        try {
            // if (!RestauId) {
            //     //Ideally this should never happen
            //     RestauId = restauHook.restauId;
            // }
            let response = await retrieveEventsByInquiryId(
                RestauId,
                CateringInquiryId
            );
            //debugger;
            let { inquiryData } = response.data;
            let eventData = inquiryData.events && inquiryData.events[0];
            if (inquiryData.conversationData) {
                if (inquiryData.conversationData.conversation) {
                    setConversation(inquiryData.conversationData.conversation);
                } else {
                    setConversation(inquiryData.conversationData);
                }

                //if there is no summary data, then this conversation has not ended or failed to end.
                //Try to summarize the conversation with ai now
                if (
                    !inquiryData.conversationData.summary ||
                    !inquiryData.conversationData.summary.eventDate
                ) {
                    setLoadingSummaryData(true);
                    response = await processConversationData(
                        RestauId,
                        CateringInquiryId
                    );
                    if (response.data) {
                        if (
                            response.data.status &&
                            response.data.status === true
                        ) {
                            //processed and fetched the inquiry data again.
                            console.log(
                                'Summarized Inquiry data: ',
                                response.data.inquiryData
                            );
                            eventData = response.data.inquiryData;
                        } else {
                            //failed to summarize the conversation data
                            console.log(
                                'Failed to summarize the conversation data.'
                            );
                        }
                    }
                    setLoadingSummaryData(false);
                }
            } else setConversation(null);
            // To update later for multiple events under the same inquiryId
            const { orders, ...formData } = eventData;
            if (orders) {
                setEventOrder(orders);
            } else {
                setEventOrder(null);
            }
            updateForm(formData);
            let eventTitle = eventData.eventName
                ? eventData.eventName
                : eventData.customerName + "'s Event";
            setDisplayTitle(eventTitle);
        } catch (error) {
            console.log('Error fetching event: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        updateTitleAndEventData();
    }, [location.pathname]);

    return (
        <Grid container direction="column">
            <Grid
                item
                xs={12}
                sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Grid item>
                    <Typography component="h2" variant="h5">
                        {displayTitle}
                    </Typography>
                    {loadingSummaryData && (
                        <Typography variant="h6">
                            Fetching Summary data
                            <CircularProgress
                                size={12}
                                sx={{ ml: 0.5, color: 'black' }}
                            />
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    {newEventPage ? (
                        <></>
                    ) : (
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => navigate('/catering/newevent')}
                        >
                            Create New Event
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {cloneElement(children, {
                    loadingSummaryData,
                    setLoadingSummaryData,
                })}
            </Grid>
        </Grid>
    );
}

export default CateringContent;
