import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import CateringContext from '../../../CateringContext';
import CateringEventContext from '../CateringEventContext';
import Conversation from './Conversation';
import {
    TextField,
    MenuItem,
    InputAdornment,
    Typography,
    Grid,
    IconButton,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import { useStyles } from '../../../../home/useStyles';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import Utils from '../../../../utils/Utils';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const BasicEventInfo = () => {
    const classes = useStyles();
    const { restauHook } = useContext(RestauInfoContext);
    const {
        eventType,
        eventStatus,
        formConfig,
        formFields,
        form,
        updateForm,
        loading,
        configLoading,
        lostReasons,
        rejectReasons,
        newEventPage,
        retrieveEventsByInquiryId,
        setDisplayTitle,
        setLoadingSummaryData,
        processConversationData,
        setConversation,
    } = useContext(CateringContext);
    const { RestauId, CateringInquiryId } = useParams();
    const { requiredFields, errors } = useContext(CateringEventContext);

    useEffect(() => {
        //debugger;
        const updateFormData = async () => {
            //debugger;
            let response = await retrieveEventsByInquiryId(
                RestauId,
                CateringInquiryId
            );
            if (!response) return;
            let { inquiryData } = response.data;
            let eventData = inquiryData.events && inquiryData.events[0];
            if (inquiryData.conversationData) {
                if (inquiryData.conversationData.conversation) {
                    setConversation(inquiryData.conversationData.conversation);
                } else {
                    setConversation(inquiryData.conversationData);
                }

                //if there is no summary data, then this conversation has not ended or failed to end.
                //Try to summarize the conversation with ai now
                if (
                    !inquiryData.conversationData.summary ||
                    !inquiryData.conversationData.summary.eventDate
                ) {
                    setLoadingSummaryData(true);
                    response = await processConversationData(
                        RestauId,
                        CateringInquiryId
                    );
                    if (response.data) {
                        if (
                            response.data.status &&
                            response.data.status === true
                        ) {
                            //processed and fetched the inquiry data again.
                            console.log(
                                'Summarized Inquiry data: ',
                                response.data.inquiryData
                            );
                            eventData = response.data.inquiryData[0];
                        } else {
                            //failed to summarize the conversation data
                            console.log(
                                'Failed to summarize the conversation data.'
                            );
                        }
                    }
                    setLoadingSummaryData(false);
                }
            } else {
                setConversation(null);
            }

            //debugger;
            // To update later for multiple events under the same inquiryId
            updateForm(eventData);
            let eventTitle = eventData.eventName
                ? eventData.eventName
                : eventData.customerName + "'s Event";
            setDisplayTitle(eventTitle);
        };
        updateFormData();
    }, [CateringInquiryId]);

    const renderAdditionalText = (field) => {
        const selectedOption = field.field_type_dropdown.options.find(
            (option) => option.label === form[field.field_name]
        );
        if (!selectedOption || !selectedOption.desc) return null;
        return <Typography>{selectedOption.desc}</Typography>;
    };

    const renderField = (field) => {
        switch (field.field_type) {
            case 'Text':
                return (
                    <TextField
                        required={requiredFields.includes(field.field_name)}
                        label={field.field_label}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '90%' }}
                        value={form[field.field_name]}
                        helperText={
                            !errors[field.field_name]
                                ? null
                                : errors[field.field_name]
                        }
                        onChange={(e) =>
                            updateForm({
                                ...form,
                                [field.field_name]: e.target.value,
                            })
                        }
                        error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            field.field_type_text.input_adornment
                                ? {
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              {
                                                  field.field_type_text
                                                      .input_adornment
                                              }
                                          </InputAdornment>
                                      ),
                                  }
                                : null
                        }
                    />
                );
            case 'Date':
                return (
                    <DatePicker
                        label={field.field_label}
                        size="small"
                        margin="normal"
                        sx={{ width: '90%' }}
                        value={
                            form[field.field_name]
                                ? Utils.convertTimestamp(
                                      form[field.field_name],
                                      1,
                                      true
                                  )
                                : null
                        }
                        onChange={(newDate) => {
                            updateForm({
                                ...form,
                                [field.field_name]: newDate,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                size="small"
                                margin="normal"
                                sx={{ width: '90%' }}
                                {...params}
                            />
                        )}
                    />
                );
            case 'Time':
                var minTime = moment()
                    .set('hour', field.field_type_time.start_hour)
                    .set('minute', field.field_type_time.start_min);
                var maxTime = moment()
                    .set('hour', field.field_type_time.end_hour)
                    .set('minute', field.field_type_time.end_min);
                return (
                    <TimePicker
                        label={field.field_label}
                        size="small"
                        margin="normal"
                        sx={{ width: '90%' }}
                        value={
                            form[field.field_name]
                                ? moment(form[field.field_name], 'h:mmA')
                                : null
                        }
                        onChange={(newValue) => {
                            updateForm({
                                ...form,
                                [field.field_name]: newValue.format('h:mmA'),
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                size="small"
                                margin="normal"
                                sx={{ width: '90%' }}
                                {...params}
                            />
                        )}
                    />
                );
            case 'Number':
                return (
                    <TextField
                        required={requiredFields.includes(field.field_name)}
                        label={field.field_label}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '90%' }}
                        value={form[field.field_name]}
                        helperText={
                            !errors[field.field_name]
                                ? null
                                : errors[field.field_name]
                        }
                        error={!!errors[field.field_name]}
                        onChange={(e) =>
                            updateForm({
                                ...form,
                                [field.field_name]: e.target.value,
                            })
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            field.field_type_number.input_adornment
                                ? {
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              {
                                                  field.field_type_number
                                                      .input_adornment
                                              }
                                          </InputAdornment>
                                      ),
                                  }
                                : null
                        }
                    />
                );
            case 'Dropdown':
                return (
                    <div style={{ width: '90%' }}>
                        <TextField
                            required={requiredFields.includes(field.field_name)}
                            select
                            label={field.field_label}
                            size="small"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '100%' }}
                            value={form[field.field_name]}
                            onChange={(e) => {
                                updateForm({
                                    ...form,
                                    [field.field_name]: e.target.value,
                                });
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {field.field_type_dropdown.options.map((option) => (
                                <MenuItem key={option.id} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div>{renderAdditionalText(field)}</div>
                    </div>
                );
            case 'Text Area':
                return (
                    <TextField
                        label={field.field_label}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        multiline
                        minRows={field.field_type_text_area.minRow}
                        sx={{ width: '90%' }}
                        value={form[field.field_name]}
                        onChange={(e) =>
                            updateForm({
                                ...form,
                                [field.field_name]: e.target.value,
                            })
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {configLoading ||
            loading ||
            !restauHook ||
            !restauHook.restauTimeZone ? (
                <img
                    className={classes.loadingImage}
                    src="/img/spinner.gif"
                    alt="loading"
                />
            ) : (
                formConfig &&
                form && (
                    <Grid container>
                        <Grid
                            container
                            item
                            spacing={1}
                            sx={{ alignItems: 'flex-start' }}
                        >
                            <Grid item xs={12} sx={{ mt: 4 }}>
                                <Typography color="primary" variant="h6">
                                    Customer Details
                                </Typography>
                            </Grid>
                            {formConfig.fields.map((field) => {
                                if (
                                    field.enable_field &&
                                    (field.field_name === 'customerName' ||
                                        field.field_name === 'phone' ||
                                        field.field_name === 'email')
                                ) {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            key={field.field_name}
                                            sx={{ display: 'flex' }}
                                        >
                                            {renderField(field)}
                                            {field.field_name === 'phone' &&
                                            form[field.field_name].length !==
                                                0 ? (
                                                <IconButton
                                                    color="primary"
                                                    href={`tel:${
                                                        form[field.field_name]
                                                    }`}
                                                    sx={{ padding: 0.5 }}
                                                >
                                                    <PhoneIcon
                                                        sx={{ fontSize: 30 }}
                                                    />
                                                </IconButton>
                                            ) : field.field_name === 'email' &&
                                              form[field.field_name].length !==
                                                  0 ? (
                                                <IconButton
                                                    color="primary"
                                                    href={`mailto:${
                                                        form[field.field_name]
                                                    }`}
                                                    sx={{ padding: 0.5 }}
                                                >
                                                    <EmailIcon
                                                        sx={{ fontSize: 30 }}
                                                    />
                                                </IconButton>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>

                        <Grid
                            item
                            container
                            spacing={2}
                            sx={{ alignItems: 'flex-start' }}
                        >
                            <Grid item xs={12} sx={{ mt: 4 }}>
                                <Typography color="primary" variant="h6">
                                    Event Details
                                </Typography>
                            </Grid>
                            {formConfig.fields.map((field) => {
                                if (
                                    field.enable_field &&
                                    field.field_name !== 'customerName' &&
                                    field.field_name !== 'phone' &&
                                    field.field_name !== 'email'
                                ) {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            key={field.field_name}
                                            sx={{ display: 'flex' }}
                                        >
                                            {renderField(field)}
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>

                        <Grid
                            container
                            item
                            spacing={1}
                            sx={{ alignItems: 'flex-start' }}
                        >
                            <Grid item xs={12} sx={{ mt: 4 }}>
                                <Typography color="primary" variant="h6">
                                    Internal Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                    required
                                    select
                                    id="eventStatus"
                                    label="Event Status"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    helperText={
                                        !errors.eventStatus
                                            ? null
                                            : errors.eventStatus
                                    }
                                    error={!!errors.eventStatus}
                                    value={form.eventStatus}
                                    sx={{ width: '90%' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) =>
                                        updateForm({
                                            eventStatus: e.target.value,
                                        })
                                    }
                                >
                                    {eventStatus.map((n) => (
                                        <MenuItem key={n.value} value={n.value}>
                                            {n.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                {form.eventStatus === 'FollowUp' ? (
                                    <div>
                                        <DatePicker
                                            size="small"
                                            label="Follow-up Due Date"
                                            variant="outlined"
                                            autoOk
                                            value={
                                                form.followupDueDate
                                                    ? Utils.convertTimestamp(
                                                          form.followupDueDate,
                                                          1,
                                                          true
                                                      )
                                                    : null
                                            }
                                            onChange={(newDate) => {
                                                updateForm({
                                                    followupDueDate: newDate,
                                                });
                                            }}
                                            inputVariant="outlined"
                                            margin="normal"
                                            minDate={moment().add(0, 'days')}
                                            maxDate={moment().add(90, 'days')}
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    sx={{ width: '90%' }}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {form.eventStatus === 'Lost' ? (
                                    <div>
                                        <TextField
                                            required
                                            select
                                            id="eventStatusReason"
                                            label="Reason for Lost Event"
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            value={form.eventStatusReason}
                                            sx={{ width: '90%' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) =>
                                                updateForm({
                                                    eventStatusReason:
                                                        e.target.value,
                                                })
                                            }
                                        >
                                            {lostReasons.map((n) => (
                                                <MenuItem key={n} value={n}>
                                                    {n}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {form.eventStatus === 'Rejected' ? (
                                    <div>
                                        <TextField
                                            required
                                            select
                                            id="eventStatusReason"
                                            label="Reason for Rejected Event"
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            value={form.eventStatusReason}
                                            sx={{ width: '90%' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) =>
                                                updateForm({
                                                    eventStatusReason:
                                                        e.target.value,
                                                })
                                            }
                                        >
                                            {rejectReasons.map((n) => (
                                                <MenuItem key={n} value={n}>
                                                    {n}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                {form.eventStatusReason === 'Other' ? (
                                    <div>
                                        <TextField
                                            id="OtherStatusReasons"
                                            label="Reason Description"
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            minRows={1}
                                            value={form.OtherStatusReasons}
                                            sx={{ width: '90%' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) =>
                                                updateForm({
                                                    OtherStatusReasons:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                    id="InternalNotes"
                                    label="Internal Notes"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    minRows={3}
                                    value={form.internalNotes}
                                    sx={{ width: '90%' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) =>
                                        updateForm({
                                            internalNotes: e.target.value,
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>

                        {newEventPage ? null : (
                            <div>
                                <Typography>
                                    Submitted on:{' '}
                                    {Utils.convertTimestamp(
                                        form.submissionTimestamp,
                                        3,
                                        false,
                                        restauHook.restauTimeZone
                                    )}
                                </Typography>
                                <Typography>Source : {form.source}</Typography>
                                <Conversation />
                            </div>
                        )}
                    </Grid>
                )
            )}
        </div>
    );
};

export default BasicEventInfo;
